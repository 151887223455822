import './AreaFacilitador.css';
import IframeEmbed from '../IframeEmbed/IframeEmbed';
import ilustracaoModulo from '../assets/ilustracaoModulo.svg'
import DropdownList from '../DropdownList/DropdownList';
import { Row, Col } from 'react-bootstrap'
import React, { useState, useEffect } from 'react';


function Slide({temasestudo, 
                src, 
                openNewTab, 
                apresentacaoPersonalizada,
                dropdownoptions,
                dropdownselectedmodulo,
                dropdownonselect,
                selectedPraticaId}) {

  return (
    <Row id="Slide" style={{height: "28em", paddingTop: '3%'}}>
      {temasestudo.length ?
        <>
              <Col></Col>
              <Col>
                <IframeEmbed 
                  src={src}
                  width="500px"
                  height="400px"
                  border="black 5px dashed"
                  openNewTab = {openNewTab}/>
              </Col>
              <Col>
            <div id='apresentacao_personalizada_indicador' style={{ display: "flex", flexDirection: "column", marginLeft: '10px'}}>
              <div id="nome_do_tema_selecionado">{apresentacaoPersonalizada.nome_tema}</div>
              <div id="link_apresentacao_personalizada">
                {apresentacaoPersonalizada.flag_material_padrao ? 'Material Padrão' : <i>Material Personalizado</i>}
              </div>
              <div id="data_apresentacao_personalizada">Data de criação: {apresentacaoPersonalizada.data_criacao.replace(' GMT', '')}</div>
              {temasestudo[selectedPraticaId]['link_apresentacao']['link_apresentacao_padrao'] !== temasestudo[selectedPraticaId]['link_apresentacao']['link_apresentacao'] &&
                <a id="apresentacao_padrao" href={temasestudo[selectedPraticaId]['link_apresentacao']['link_apresentacao_padrao']} target="_blank" rel="noopener noreferrer">Material Padrão</a>
              }
            </div>
            </Col>
        </>
        : <Row style={{  marginTop: '5%' }} className='div-total-image'>
        <Col md={4} className="image-container">
            <Row><img style={{width: '200px' }}id="img-sem-micro" src={ilustracaoModulo} alt="Imagem" /></Row>
            <Row>
              <p>Para começar, selecione um módulo</p>
            </Row>
            <Row>
              <DropdownList
                placeholder='Selecione um módulo '
                options={dropdownoptions}
                selectedOption={dropdownselectedmodulo}
                onSelect={dropdownonselect}
              />
            </Row>
        </Col>
    </Row>}
    <div>
      
    </div>
    </Row>
  );
}

export default Slide;