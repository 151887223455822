import React, { useState } from 'react';
import './Login.css';
import { useNavigate  } from 'react-router-dom';
import { Dialog, DialogTitle, DialogContent, DialogContentText, CircularProgress } from '@mui/material';
import { setAuthData } from '../../auth';
// import * as jwt_decode from 'jwt-decode';
// @ts-ignore 
// import jwt_decode from "jwt-decode";
// import { decode } from 'jsonwebtoken';
import { jwtDecode } from 'jwt-decode';
import logoHarve from '../../components/assets/logoHarve.svg'
import Divider from '@mui/material/Divider';

const apiUrl = process.env.REACT_APP_API_HOST;
// const apiUrl = "http://127.0.0.1:5000";

function Login() {
  const navigate = useNavigate();
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  // Função para decodificar o token e obter as informações do usuário
  const getUserInfoFromToken = (token) => {
    try {
      const decoded = jwtDecode(token);
      return decoded;
    } catch (error) {
      console.error('Erro ao decodificar o token:', error);
      return null;
    }
  };

  const handleLogin = async (e) => {
    e.preventDefault(); // Evita o envio padrão do formulário
    setLoading(true);
    setOpen(true);
    fetch(`${apiUrl}/api/login`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ username, password }),
    })
    .then((response) => {
      if (!response.ok) {
        return response.json().then(data => {
          throw new Error(data.message);
        });
      }
      return response.json();
    })
    .then((response) => {
      const user = { email: username, password: password, level:getUserInfoFromToken(response.token).sub.level };
      setAuthData(response);
      setLoading(false);
      setOpen(false);
      navigate('/AreaFacilitador', { state: {user} });
  })
    .then((data) => console.log(data))
    .catch((error) => {
      setLoading(false);
      setOpen(false);
      alert(error.message);
      console.error('Erro:', error);});
  };

  return (
    <div className="login-container">
      <div className='def'> <img id='logo-harve-login' src={logoHarve} alt='logo Harve'></img></div>
      <div className='title-login'><h2  className='title-login'>Área do Facilitador</h2></div>
      <form>
        <input
          type="text" 
          placeholder="Nome de usuário" 
          value={username} 
          onChange={(e) => setUsername(e.target.value)} 
          required="required" />
           <Divider sx={{ my: 0.5 }} />
        <input
          type="password" 
          placeholder="Senha" 
          onChange={(e) => setPassword(e.target.value)}
          required="required" />
           <Divider sx={{ my: 1.5 }} />
        <button type="submit" onClick={handleLogin}>Login</button>
      </form>
      <Dialog open={open}>
        <DialogTitle>Por favor, aguarde</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {loading ? <CircularProgress /> : 'Processando sua solicitação...'}
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </div>
  );
}



export default Login;