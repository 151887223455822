import React, { useState, useEffect } from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';

function ModalAviso({ texto, show, setTextoModalSalvar, setMostrarModalSalvar }) {
  const [isVisible, setIsvisible] = useState(show); // Declaração da variável timer

  useEffect(() => {
    setIsvisible(show);
    document.body.style.overflow = 'scroll';
    document.body.style.paddingLeft = '0px';
    document.body.style.paddingRight = '0px';
  }, [show]);
  
  const handleClose = () => {
    // Reinicia modal de salvar
    setTextoModalSalvar('');
    setMostrarModalSalvar(false);
    setIsvisible(false);
    // setTempoRestante(5);
  };

  // const desfazer = () => {
  //   clearInterval(timer);
  //   // setTempoRestante(5);
  //   desfazerAcao();
  // };

  return (
    // <div className={`modal ${show ? 'fade-slide-enter' : 'fade-slide-exit'}`}>
    <div className='modalAviso'>
    <Modal show={isVisible}
           onHide={handleClose}
           backdrop="true"
           size="lg"
          //  dialogClassName="bottom-right-modal"
          //  contentClassName="modal3"
          >
      <Modal.Dialog 
          //  contentClassName="modal3"
           style={{margin:0}}>
        {/* <Modal.Header closeButton>
          <Modal.Title>Modal title</Modal.Title>
        </Modal.Header> */}

        <Modal.Body>
          {texto}
          {/* <ProgressBar variant="info" animated now={Math.ceil(tempoRestante/5)} /> */}
        </Modal.Body>

        <Modal.Footer style={{flexDirection: "row-reverse"}}>
          <Button variant="dark" onClick={handleClose}>OK</Button>
          {/* <Button variant="primary">Save changes</Button> */}
        </Modal.Footer>
      </Modal.Dialog>
      {/* <div className="modal-content">
        <p>Desfazer em {tempoRestante} segundos</p>
        <button onClick={desfazer}>Desfazer</button>
      </div> */}
      </Modal>
    </div>
  );
}

export default ModalAviso;