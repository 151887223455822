// Routes.js
import React from 'react';
import { Route, Navigate } from 'react-router-dom';
import { isAuthenticated } from "./auth"; // Importa a função de autenticação

// Define o componente PrivateRoute
const PrivateRoute = ({ Component }) => {
    // Verifica se o usuário está autenticado
    // Se autenticado, renderiza o componente passado como prop
    // Se não autenticado, redireciona para a página de login
    return isAuthenticated() ? <Component /> : <Navigate to="/" replace />
  };

export default PrivateRoute; // Exporta o componente PrivateRoute para ser utilizado em outros lugares





