// Importações necessárias
import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import AreaFacilitador from './components/AreaFacilitador/AreaFacilitador'; // Importa o componente da área do facilitador
import AreaAdministrador from './components/AreaAdministrador/AreaAdministrador'; // Importa o componente da área do administrador
import Login from './components/Login/Login'; // Importa o componente de login
import 'bootstrap/dist/css/bootstrap.min.css'; // Importa o estilo Bootstrap
import PrivateRoute from './PrivateRoute'; // Importa o componente PrivateRoute
import AdminRoute from './AdminRoute'; // Importa o componente AdminRoute
import Teste from './components/Teste'
import SegundoTopo from './components/SegundoTopo/SegundoTopo';
import MentorModulo from './components/MentorModulo/MentorModulo';

// Define o componente App
const App = () => (
  <BrowserRouter> {/* Define o BrowserRouter para gerenciar a navegação */}
    <Routes> {/* Define as rotas da aplicação */}
      <Route path="/" element={<Login />} /> {/* Rota para a página de login */}
      <Route path='/Teste' element={<Teste />} />
      <Route path='SegundoTopo' element={<SegundoTopo />} />
      {/* Rotas para as áreas protegidas de administrador */}
      <Route path='MentorModulo' element={<AdminRoute Component={MentorModulo} />} />
      {/* Rotas para as áreas protegidas */}
      <Route path="/AreaFacilitador" element={<PrivateRoute Component={AreaFacilitador} />} />
      <Route path="/AreaAdministrador" element={<PrivateRoute Component={AreaAdministrador} />} />
    </Routes>
  </BrowserRouter>
);

export default App; // Exporta o componente App para ser utilizado em outros lugares da aplicação






