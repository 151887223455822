import React, { useState, useEffect } from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import ProgressBar from 'react-bootstrap/ProgressBar';
import './ModalDesfazer.css';

function ModalDesfazer({ desfazerAcao, show, onCloseModalCallback }) {
  const [tempoRestante, setTempoRestante] = useState(500); // 5 segundos para desfazer a ação
  const [timer, setTimer] = useState(null); // Declaração da variável timer
  const [isVisible, setIsvisible] = useState(show); // Declaração da variável timer

  useEffect(() => {
    setIsvisible(show);
    setTempoRestante(500);
    const timerID = setInterval(() => {
      setTempoRestante((prevTempo) => prevTempo - 1);
    }, 8);

    setTimer(timerID); // Armazenar o ID do intervalo na variável timer

    return () => clearInterval(timerID);
  }, [show]);

  const handleClose = () => {
    clearInterval(timer);
    setIsvisible(false);
    onCloseModalCallback();
    // setTempoRestante(5);
  };

  const desfazer = () => {
    clearInterval(timer);
    // setTempoRestante(5);
    desfazerAcao();
  };

  return (
    // <div className={`modal ${show ? 'fade-slide-enter' : 'fade-slide-exit'}`}>
    <div className='mod'>
    <Modal show={isVisible}
           onHide={handleClose}
           backdrop="true"
           size="lg"
           dialogClassName="bottom-right-modal"
          //  contentClassName="modal3"
          >
      <Modal.Dialog 
          //  contentClassName="modal3"
           style={{margin:0}}>
        {/* <Modal.Header closeButton>
          <Modal.Title>Modal title</Modal.Title>
        </Modal.Header> */}

        <Modal.Body>
          {/* <p>Desfazer em {tempoRestante} segundos</p> */}
          <ProgressBar variant="info" animated now={Math.ceil(tempoRestante/5)} />
        </Modal.Body>

        <Modal.Footer style={{flexDirection: "row-reverse"}}>
          <Button variant="dark" onClick={desfazer}>Desfazer</Button>
          {/* <Button variant="primary">Save changes</Button> */}
        </Modal.Footer>
      </Modal.Dialog>
      {/* <div className="modal-content">
        <p>Desfazer em {tempoRestante} segundos</p>
        <button onClick={desfazer}>Desfazer</button>
      </div> */}
      </Modal>
    </div>
  );
}

export default ModalDesfazer;