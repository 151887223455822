import React, { useState, useEffect } from 'react';
import { Toast, ToastContainer } from 'react-bootstrap';

const TemporaryToast = ({ message, duration, callback }) => {
  const [show, setShow] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setShow(false);
      if (callback) {
        callback(false); // Chama a função de callback quando o temporizador expira
      }
    }, duration);

    // Cleanup timer on component unmount
    return () => clearTimeout(timer);
  }, [duration]);

  return (
    <ToastContainer position="bottom-end" className="p-3">
        <Toast onClose={() => setShow(false)} show={show} delay={duration} autohide animation>
          <Toast.Body>{message}</Toast.Body>
        </Toast>
    </ToastContainer>
  );
};

export default TemporaryToast;
