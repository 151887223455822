import React, { useState, useEffect } from "react"; // Importação dos hooks useState e useEffect do React
import "./ComparaModificacoes.css"; // Importação do arquivo de estilo CSS
import { List, ListItem, ListItemText } from "@mui/material"; // Importação dos componentes List, ListItem e ListItemText do Material-UI
import { Row, Col } from "react-bootstrap";

function ComparaModificacoes({
  historicoAtualizacoes,
  historicoEmAguardo,
  itemSelecionado,
  handleButtonClick,
}) {
  
  function formatStringofArray(nestedListString) {
    // Parseia a string para um array de arrays
    const nestedArray = JSON.parse(nestedListString);

    // Formata cada subarray com o texto "Aula X"
    const formatted = nestedArray.map(
      (lesson, index) => `Aula ${index + 1}: [${lesson.join(", ")}]`
    );
    return formatted;
  }

  function formatStringofArrayPersonalizado(
    nestedListStringPadrao,
    nestedListStringPersonalizado
  ) {
    // Parseia as strings para arrays de arrays
    const nestedArray1 = JSON.parse(nestedListStringPadrao);
    const nestedArray2 = JSON.parse(nestedListStringPersonalizado);

    // Função para formatar os subarrays com diferenças em negrito
    const formatLesson = (lesson1, lesson2, index) => {
      return (
        <span key={index}>
          {`Aula ${index + 1}: [`}
          {lesson1
            .map((num, i) =>
              num === lesson2[i] ? (
                <span key={i}>{num}</span>
              ) : (
                <b key={i}>{num}</b>
              )
            )
            .reduce((prev, curr) => [prev, ", ", curr])}
          {`]`}
        </span>
      );
    };

    // Formata cada subarray
    const formatted = nestedArray2.map((lesson, index) =>
      formatLesson(lesson, nestedArray1[index], index)
    );
    return formatted;
  }
  function renderizarValor(valor, personalizado) {
    // Declara uma função para renderizar o valor de um item
    if (!valor) {
      // Verifica se o valor é nulo
      return null; // Retorna nulo
    }
    // Verifica se não é personalizado
    if (
      valor.tipo_modificacao === "handson" ||
      valor.tipo_modificacao === "novahandson"
    ) {
      // Verifica se o tipo de modificação é 'handson'
      return valor.modificacoes.texto_hands_on_padrao ? (
        <div>
          {personalizado
            ? valor.modificacoes.texto_hands_on_personalizado
            : valor.modificacoes.texto_hands_on_padrao}
        </div>
      ) : (
        <i>NULL</i>
      );
    } else if (
      valor.tipo_modificacao === "descobertaguiada" ||
      valor.tipo_modificacao === "novadescobertaguiada"
    ) {
      // Verifica se o tipo de modificação é 'descobertaguiada'
      return valor.modificacoes.texto_descoberta_guiada_padrao ? (
        <div>
          {personalizado
            ? valor.modificacoes.texto_descoberta_guiada_personalizado
            : valor.modificacoes.texto_descoberta_guiada_padrao}
        </div>
      ) : (
        <i>NULL</i>
      );
    } else if (
      valor.tipo_modificacao === "apresentacao" ||
      valor.tipo_modificacao === "novaapresentacao"
    ) {
      // Verifica se o tipo de modificação é 'apresentacao'
      return valor.modificacoes.link_apresentacao_padrao ? (
        <div>
          <a
            href={
              personalizado
                ? valor.modificacoes.link_apresentacao_personalizado
                : valor.modificacoes.link_apresentacao_padrao
            }
            target="_blank"
            rel="noopener noreferrer"
          >
            {personalizado
              ? valor.modificacoes.link_apresentacao_personalizado
              : valor.modificacoes.link_apresentacao_padrao}
          </a>
        </div>
      ) : (
        <i>NULL</i>
      );
    } else if (valor.tipo_modificacao === "ordem_aulas") {
      // Verifica se o tipo de modificação é 'ordem_aulas' e se a lista de temas padrão é um array
      return (
        <div>
          {personalizado
            ? formatStringofArray(
                valor.modificacoes.sequencia_versoes_ids_padrao
              ).map((lesson, index) => (
                <div key={index}>
                  {lesson}
                  <br />
                </div>
              ))
            : formatStringofArrayPersonalizado(
                valor.modificacoes.sequencia_versoes_ids_padrao,
                valor.modificacoes.sequencia_versoes_ids_personalizado
              ).map((lesson, index) => (
                <div key={index}>
                  {lesson}
                  <br />
                </div>
              ))}
        </div>
      );
    }
  }

  return (
    <div id="teste">
      <h1 style={{ fontWeight: "bold", fontSize: "xx-large" }}>Alterações</h1>
      <div id="janelaModificacoes">
        <p style={{ marginLeft: "3%", fontSize: "larger", marginTop: "1%" }}>
          Personalizado
        </p>
        <div id="materialModificado">
          <div className="max-width-content">
            {/* Adicionando classe para o container do conteúdo do item */}
            {/* Renderização dos detalhes do material modificado */}
            {historicoAtualizacoes === "No logs"
              ? "Sem dados no momento"
              : (
                  <ListItem selected>
                    <ListItemText
                      style={{
                        maxWidth: "100%",
                        backgroundColor: "transparent",
                      }}
                    >
                      {itemSelecionado.status === "em_aguardo"
                        ? renderizarValor(
                            historicoEmAguardo[itemSelecionado.index],
                            true
                          )
                        : renderizarValor(
                            historicoAtualizacoes[itemSelecionado.index],
                            true
                          )}
                    </ListItemText>
                  </ListItem>
                )}
          </div>
        </div>
        <p style={{ marginLeft: "3%", fontSize: "larger", marginTop: "1%" }}>
          Padrão
        </p>
        <div id="materialPadrao">
          <div className="max-width-content">
            {/* Adicionando classe para o container do conteúdo do item */}
            {/* Renderização dos detalhes do material modificado */}
            {historicoAtualizacoes === "No logs"
              ? "Sem dados no momento"
              : (
                  <ListItem selected>
                    <ListItemText
                      style={{
                        maxWidth: "100%",
                        backgroundColor: "transparent",
                      }}
                    >
                      {itemSelecionado.status === "em_aguardo"
                        ? renderizarValor(
                            historicoEmAguardo[itemSelecionado.index],
                            false
                          )
                        : renderizarValor(
                            historicoAtualizacoes[itemSelecionado.index],
                            false
                          )}
                    </ListItemText>
                  </ListItem>
                )}
          </div>
        </div>
        <Row id="boxBotoes">
          {!(
            (historicoAtualizacoes.length > 0 ||
              historicoEmAguardo.length > 0) &&
            (itemSelecionado.status === "pendente"
              ? historicoAtualizacoes[itemSelecionado.index] &&
                historicoAtualizacoes[itemSelecionado.index].tipo_modificacao ===
                  "ordem_aulas"
              : historicoEmAguardo[itemSelecionado.index] &&
                historicoEmAguardo[itemSelecionado.index].tipo_modificacao ===
                  "ordem_aulas")
          ) && (
            <Col className="botaoAcao">
              <button
                className="botaobotaoAcao"
                id="botaoDescartar"
                variant="contained"
                onClick={() => handleButtonClick("rejeitado")}
                disabled={
                  (historicoAtualizacoes.length > 0 ||
                    historicoEmAguardo.length > 0) &&
                  (itemSelecionado.status === "pendente"
                    ? historicoAtualizacoes[itemSelecionado.index] &&
                      historicoAtualizacoes[itemSelecionado.index]
                        .tipo_modificacao === "ordem_aulas"
                    : historicoEmAguardo[itemSelecionado.index] &&
                      historicoEmAguardo[itemSelecionado.index].tipo_modificacao ===
                        "ordem_aulas")
                }
              >
                <strong>Descartar</strong>
              </button>
            </Col>
          )}
          {!(
            (historicoAtualizacoes.length > 0 ||
              historicoEmAguardo.length > 0) &&
            (itemSelecionado.status === "em_aguardo" ||
              (itemSelecionado.status === "pendente"
                ? historicoAtualizacoes[itemSelecionado.index] &&
                  historicoAtualizacoes[itemSelecionado.index].tipo_modificacao ===
                    "ordem_aulas"
                : historicoEmAguardo[itemSelecionado.index] &&
                  historicoEmAguardo[itemSelecionado.index].tipo_modificacao ===
                    "ordem_aulas"))
          ) && (
            <Col className="botaoAcao">
              <button
                className="botaobotaoAcao"
                id="botaoAguardar"
                variant="contained"
                onClick={() => handleButtonClick("em_aguardo")}
                disabled={
                  (historicoAtualizacoes.length > 0 ||
                    historicoEmAguardo.length > 0) &&
                  (itemSelecionado.status === "em_aguardo" ||
                    (itemSelecionado.status === "pendente"
                      ? historicoAtualizacoes[itemSelecionado.index] &&
                        historicoAtualizacoes[itemSelecionado.index]
                          .tipo_modificacao === "ordem_aulas"
                      : historicoEmAguardo[itemSelecionado.index] &&
                        historicoEmAguardo[itemSelecionado.index].tipo_modificacao ===
                          "ordem_aulas"))
                }
              >
                <strong>Aguardar</strong>
              </button>
            </Col>
          )}
          {!(
            (historicoAtualizacoes.length > 0 ||
              historicoEmAguardo.length > 0) &&
            (itemSelecionado.status === "pendente"
              ? historicoAtualizacoes[itemSelecionado.index] &&
                historicoAtualizacoes[itemSelecionado.index].tipo_modificacao ===
                  "ordem_aulas"
              : historicoEmAguardo[itemSelecionado.index] &&
                historicoEmAguardo[itemSelecionado.index].tipo_modificacao ===
                  "ordem_aulas")
          ) && (
            <Col className="botaoAcao">
              <button
                className="botaobotaoAcao"
                id="botaoAtualizar"
                variant="outlined"
                onClick={() => handleButtonClick("aprovado")}
                disabled={
                  (historicoAtualizacoes.length > 0 ||
                    historicoEmAguardo.length > 0) &&
                  (itemSelecionado.status === "pendente"
                    ? historicoAtualizacoes[itemSelecionado.index] &&
                      historicoAtualizacoes[itemSelecionado.index]
                        .tipo_modificacao === "ordem_aulas"
                    : historicoEmAguardo[itemSelecionado.index] &&
                      historicoEmAguardo[itemSelecionado.index].tipo_modificacao ===
                        "ordem_aulas")
                }
              >
                <strong>Atualizar</strong>
              </button>
            </Col>
          )}
          {(historicoAtualizacoes.length > 0 ||
            historicoEmAguardo.length > 0) &&
            (itemSelecionado.status === "pendente"
              ? historicoAtualizacoes[itemSelecionado.index] &&
                historicoAtualizacoes[itemSelecionado.index].tipo_modificacao ===
                  "ordem_aulas"
              : historicoEmAguardo[itemSelecionado.index] &&
                historicoEmAguardo[itemSelecionado.index].tipo_modificacao ===
                  "ordem_aulas") && (
              <Col className="botaoAcao">
                <button
                  className="botaobotaoAcao"
                  id="botaoAtualizar"
                  variant="outlined"
                  onClick={() => handleButtonClick("check")}
                  // disabled={((historicoAtualizacoes.length>0) || (historicoEmAguardo.length>0)) &&
                  //             (historicoSelecionado === "pendente" ?
                  //               historicoAtualizacoes[itemSelecionado].tipo_modificacao === 'ordem_aulas' :
                  //               historicoEmAguardo[itemSelecionado].tipo_modificacao === 'ordem_aulas')}
                >
                  <strong>OK</strong>
                </button>
              </Col>
            )}
        </Row>
      </div>
    </div>
  );
}

export default ComparaModificacoes; // Exportação do componente ComparaModificacoes
