import React, { useState } from 'react'; // Importação dos hooks useState e useEffect do React
import './HistoricoAlteracoes.css'; // Importação do arquivo de estilo CSS
import { List, ListItem, ListItemText } from '@mui/material'; // Importação dos componentes List, ListItem e ListItemText do Material-UI

function HistoricoAlteracoes({ historicoAtualizacoes, setItemSelecionado, historicoEmAguardo}) { // Declaração do componente HistoricoAlteracoes com as props carregarHistorico, setItemSelecionado e historicoEmAguardo
  const [itemSelecionado, setItemSelecionadoLocal] = useState({'index':0, 'status':'pendente'}); // Definição do estado itemSelecionado e da função setItemSelecionadoLocal utilizando o hook useState, inicializado com o valor 0

  const renderizarItens = (history, status) => {
    // Estrutura do histórico:
    // NomeModulo: NomeTema: EnumTipo: Array: {data_modificacao, facilitador, id_log, modificacoes: {}, status, tipo_modificacao}
    try {
      return (
              history.map((log, index) => (
                <ListItem // Componente ListItem
                  key={index} // Chave única para o chave
                  button // O chave é um botão
                  selected={(index === itemSelecionado.index) && (status === itemSelecionado.status)} // Verificação se o chave está selecionado
                  onClick={() => { // Função executada ao clicar no chave
                    setItemSelecionado({
                      'index':index,
                      'status': status
                    }); // Chamada da função setItemSelecionado para atualizar o chave selecionado
                    setItemSelecionadoLocal({
                      'index':index,
                      'status': status
                    }); // Chamada da função setItemSelecionado para atualizar o chave selecionado
                  }}
                >
                  <ListItemText>
                    <strong>Módulo:</strong> {log['nome_modulo']}<br/>
                    <strong>Tema:</strong> {log['nome_temaestudo']}<br/>
                    <strong>Facilitador:</strong> {log['facilitador']}<br/>
                    <strong>Tipo de modificação:</strong> {log['tipo_modificacao']}<br/>
                    <strong>Data:</strong> {log['data_modificacao']}
                  </ListItemText>
                </ListItem>
              ))
            )
    }
    catch {
      console.error("Renderização do histórico com erro!");
    }
    
  };

  // Função para formatar os componentes de data para o formato "dd/mm/YY"
  function formatDate(date) {
    return `${String(date.getDate()).padStart(2, '0')}/${String(date.getMonth() + 1).padStart(2, '0')}/${String(date.getFullYear()).slice(-2)}`;
  }
  
  function extrairChaves(objeto, chaves) { // Declaração da função extrairChaves, que recebe um objeto e um array de chaves como parâmetros
    // Verifica se o objeto é válido
    if (!objeto || typeof objeto !== 'object') { // Verificação se o objeto é nulo ou não é um objeto
      return {}; // Retorna um objeto vazio
    }
  
    // Usa o método reduce para iterar sobre as chaves do objeto
    return Object.keys(objeto).reduce((resultado, chave) => { // Iteração sobre as chaves do objeto
      // Verifica se a chave atual está na lista de chaves desejadas
      if (chaves.includes(chave)) { // Verificação se a chave está inclusa no array de chaves
        if(chave === 'data_modificacao'){
          // Converter a string da data para um objeto Date
          const date = new Date(objeto[chave].slice(0, -4)); // Removendo GMT
          // Formatar a data
          resultado[chave] = formatDate(date);
        }
        else{
          // Adiciona a chave e seu valor ao novo objeto
          resultado[chave] = objeto[chave]; // Adiciona a chave e seu valor ao objeto de resultado
        }
      }
      return resultado; // Retorna o resultado após a iteração
    }, {}); // Objeto inicial do reduce
  }

  return (
    <div className="total-hist"> {/* Div com a classe total-hist */}
      <div className="container-rolavel"> {/* Div com a classe container-rolavel */}
        <div className="metade-container"> {/* Div com a classe metade-container */}
          <List> {/* Componente List */}
             {/* Título das modificações pendentes */}
            { (Object.keys(historicoAtualizacoes).length === 0)  // Verificação se o histórico está vazio
              ?
              (
                <div>Histórico vazio</div> // Exibição de uma mensagem caso o histórico esteja vazio
              )
              :
              (
                historicoAtualizacoes === 'No logs'
                ?
                (
                  <div>{historicoAtualizacoes}</div> // Exibição de uma mensagem caso o histórico esteja vazio
                )
                :
                (
                  renderizarItens(historicoAtualizacoes, "pendente") // Renderização dos itens
                )
              )
            }
          </List>
        </div>
        <p style={{marginLeft: '3%', fontSize: 'larger', marginTop: '3%'}}>Em Aguardo</p>
        <div className="metade-container2">
          <List>
            { (Object.keys(historicoEmAguardo).length === 0)  // Verificação se o histórico está vazio
              ?
              (
                <div>Histórico vazio</div> // Exibição de uma mensagem caso o histórico esteja vazio
              )
              :
              (
                historicoEmAguardo === 'No logs'
                ?
                (
                  <div>{historicoEmAguardo}</div> // Exibição de uma mensagem caso o histórico esteja vazio
                )
                :
                (
                  renderizarItens(historicoEmAguardo, "em_aguardo") // Renderização dos itens
                )
              )
            }
            {/* {Array.isArray(historicoEmAguardo) && historicoEmAguardo.filter((item) => item && item.status === 'em_aguardo').length === 0 && (
              <div>Histórico vazio</div>
            )}
            {Array.isArray(historicoEmAguardo) && historicoEmAguardo
              .filter((item) => item && item.status === 'em_aguardo') // Filtrar apenas os itens com status 'em_aguardo'
              .map((item, index) => (
                <ListItem
                  key={index}
                  button
                  selected={index === itemSelecionado}
                  onClick={() => {
                    callback("em_aguardo");
                    setItemSelecionado(index);
                  }}
                >
                  <ListItemText>
                    {renderizarItens(item)}
                  </ListItemText>
                </ListItem>
              ))} */}
          </List>
        </div>
      </div>
    </div>
  );
}

export default HistoricoAlteracoes; // Exportação do componente HistoricoAlteracoes
