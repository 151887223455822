import React, { useEffect } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogContentText, CircularProgress, DialogActions, TextField } from '@mui/material';

function DialogText({openflag, dialogtitle, dialogcontexttextflag}) {
  useEffect(() => {
    // setIsvisible(show);
    document.body.style.overflowY = 'scroll';
  }, [openflag]);
  return (
      <Dialog id="loadingModal" open={openflag}>
        <DialogTitle>{dialogtitle}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {dialogcontexttextflag ? <CircularProgress /> : 'Processando...'}
          </DialogContentText>
        </DialogContent>
      </Dialog>
  );
}

export default DialogText;
