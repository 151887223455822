export const autoFocoExercicio  = (descobertaguiadaRef, handsonRef, praticaSelecionadaId) => {
  // Acessa o elemento DOM do BlocoSequenciaPraticas
  const blocoSequenciaPraticasElement_descobertaguiada = descobertaguiadaRef.current;
  const blocoSequenciaPraticasElement_handson = handsonRef.current;
  
  if (blocoSequenciaPraticasElement_descobertaguiada) {
    // Encontrar o elemento do bloco selecionado
    const blocoSelecionadoElement = blocoSequenciaPraticasElement_descobertaguiada.querySelector(`[id=bloco_p-${praticaSelecionadaId}]`);

    // Garantir que o bloco selecionado existe
    if (blocoSelecionadoElement) {
      // Calcula a posição relativa do bloco em relação à div pai
      const blocoTop = blocoSelecionadoElement.offsetTop;
      const blocoHeight = blocoSelecionadoElement.offsetHeight;
      const divPaiHeight = blocoSequenciaPraticasElement_descobertaguiada.offsetHeight;

      // Calcula o valor de scrollTop para centralizar o bloco
      const scrollTopValue = blocoTop - (divPaiHeight - blocoHeight) / 2;

      // Define o valor de scrollTop da div pai
      blocoSequenciaPraticasElement_descobertaguiada.scrollTop = scrollTopValue;
    }
  }

  if (blocoSequenciaPraticasElement_handson) {
    // Encontrar o elemento do bloco selecionado
    const blocoSelecionadoElement = blocoSequenciaPraticasElement_handson.querySelector(`[id=bloco_p-${praticaSelecionadaId}]`);

    // Garantir que o bloco selecionado existe
    if (blocoSelecionadoElement) {
      // Calcula a posição relativa do bloco em relação à div pai
      const blocoTop = blocoSelecionadoElement.offsetTop;
      const blocoHeight = blocoSelecionadoElement.offsetHeight;
      const divPaiHeight = blocoSequenciaPraticasElement_handson.offsetHeight;

      // Calcula o valor de scrollTop para centralizar o bloco
      const scrollTopValue = blocoTop - (divPaiHeight - blocoHeight) / 2;

      // Define o valor de scrollTop da div pai
      blocoSequenciaPraticasElement_handson.scrollTop = scrollTopValue;
    }
  }
};

export function removeElementFromArray2D(array2D, index1D) {
  let currentIndex = 0;
  let found = false;
  let newArray2D = [];

  for (let i = 0; i < array2D.length; i++) {
    let row = array2D[i];
    let newRow = [];

    for (let j = 0; j < row.length; j++) {
      if (currentIndex === index1D) {
        found = true;
      } else {
        newRow.push(row[j]);
      }

      currentIndex++;
    }

    if (newRow.length > 0) {
      newArray2D.push(newRow);
    }

    // if (found) {
    //   break;
    // }
  }

  return newArray2D;
}