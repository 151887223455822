import React, { useState, useEffect } from "react"; // Importação dos hooks useState e useEffect do React
import "./HistoricoAlteracoesRecentes.css"; // Importação do arquivo de estilo CSS
import { List, ListItem, ListItemText } from "@mui/material"; // Importação dos componentes List, ListItem e ListItemText do Material-UI

function HistoricoAlteracoesRecentes({ historicoAprovacoesRecentes}) {
  // Declaração do componente HistoricoAlteracoesRecentes com as props carregarHistorico, setItemSelecionado e historicoEmAguardo
  const [expanded, setExpanded] = useState(false);

  return (
    <div>
      <div style={{ marginTop: "5%" }}>
        <h1
          style={{
            fontWeight: "bold",
            fontSize: "xx-large",
            marginLeft: "1%",
          }}
        >
          Histórico
        </h1>
      </div>
      <div
        id="listaReduzida"
        style={{
          overflowY: expanded ? "auto" : "hidden",
          maxHeight: expanded ? "none" : "150px",
        }}
      >
        <List>
          {/* Verifica se historicoEmAguardo é um array antes de usar a função map */}
          {Array.isArray(historicoAprovacoesRecentes) &&
          historicoAprovacoesRecentes.length > 0 ? (
            historicoAprovacoesRecentes.map((item, index) => (
              <ListItem key={index}>
                <ListItemText>
                  <div>
                    <strong>Nome Facilitador: </strong>
                    {item["facilitador"] ? item["facilitador"] : "N/A"}
                  </div>
                  <div>
                    <strong>Nome Módulo: </strong>
                    {item["nome_modulo"] ? item["nome_modulo"] : "N/A"}
                  </div>
                  <div>
                    <strong>Nome Tema: </strong>
                    {item["nome_temaestudo"] ? item["nome_temaestudo"] : "N/A"}
                  </div>
                  <div>
                    <strong>Status: </strong>
                    {item["status"] ? item["status"] : "N/A"}
                  </div>
                  <div>
                    <strong>Tipo Modificação: </strong>
                    {item["tipo_modificacao"]
                      ? item["tipo_modificacao"]
                      : "N/A"}
                  </div>
                  <div>
                    <strong>Data: </strong>
                    {item["data_modificacao"]
                      ? item["data_modificacao"]
                      : "N/A"}
                  </div>
                </ListItemText>
              </ListItem>
            ))
          ) : (
            <div>Não há histórico recente</div>
          )}
        </List>
      </div>
      {/* Botão "Ver Mais" */}
      <div className="bt-more-div">
        {/* Adicionando verificação se o botão deve ser exibido ou não */}
        {!expanded ? (
          <button id="more" onClick={() => setExpanded(true)}>
            Ver Mais
          </button>
        ) : (
          <button id="more" onClick={() => setExpanded(false)}>
            Fechar
          </button>
        )}
      </div>
    </div>
  );
}

export default HistoricoAlteracoesRecentes; // Exportação do componente HistoricoAlteracoesRecentes
