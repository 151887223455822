import React from 'react';
// import 'font-awesome/css/font-awesome.min.css';
// import 'font-awesome/css/all.min.css'; // Importe o CSS do Font Awesome 6
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUpRightAndDownLeftFromCenter } from '@fortawesome/free-solid-svg-icons';
// import { Helmet } from 'react-helmet';
import Button from 'react-bootstrap/Button'; // Importação do componente de botão do React Bootstrap

function IframeEmbed({ src, width, height, openNewTab }) {
  const iframeUrl = src;

  // const openNewTab = () => {
  //   const newTab = window.open();
  //   newTab.opener = null; // Impede que a nova aba tenha acesso ao navegador pai
  //   newTab.location.href = iframeUrl;
  // };
  return (
    // <div id='iframe-embed' style={{ border:'2px black dashed', position: 'relative', width: width || '100%', height:height || '400px'}}>
    <div id='iframe-embed' style={{ border:'2px black dashed', position: 'relative', height:height || '400px'}}>
      {/* <!-- Adicione a diretiva CSP permitindo o URL do Google Slides --> */}
      {/* <Helmet>
        <meta httpEquiv="Content-Security-Policy" content="frame-src https://docs.google.com/"></meta>
      </Helmet> */}
      <iframe
        src={src}
        width={'100%'}
        height={'100%'}
        frameBorder="0"
        allowFullScreen
        title="Iframe Title"
      ></iframe>
      <Button
        style={{
          width: '95%',
          height: '3em',
          position: 'absolute',
          bottom: '25.5em',
          right: '.5em',
          backgroundColor: '#87ef96',
          color: 'black',
          border: 'none',
          cursor: 'pointer',
        }}
        variant = "dark"
        onClick={openNewTab}
        disabled={src === ""}
      >
        {/* Abrir em nova aba */}
        {/* <FontAwesomeIcon icon={faUpRightAndDownLeftFromCenter} /> */}
        <strong> Editar apresentação </strong>
      </Button>
    </div>
  );
}

export default IframeEmbed;