// Routes.js
import React from 'react';
import { Route, Navigate } from 'react-router-dom';
import { isAuthenticated, getAuthData } from "./auth"; // Importa a função de autenticação
import { jwtDecode } from 'jwt-decode';

const isAdmin = (() => {
  if (isAuthenticated()){
    const authData = getAuthData();
    var level = ''
    if (authData){
      level = jwtDecode(authData.token).sub.level;
      if (level === 'administrador') {
        return true;
      }
    }
  }
  return false;
});
// Define o componente AdminRoute
const AdminRoute = ({ Component }) => {
    // Verifica se o usuário está autenticado
    // Se autenticado, renderiza o componente passado como prop
    // Se não autenticado, redireciona para a página de login
    return isAdmin() ? <Component /> : <Navigate to="/" replace />
  };

export default AdminRoute; // Exporta o componente AdminRoute para ser utilizado em outros lugares





