import './AreaFacilitador.css';
import React, { useState, useEffect } from 'react';
import BlocoSequenciaPraticas from '../BlocoSequenciaPraticas/BlocoSequenciaPraticas';
import { Button, Collapse, Divider } from '@mui/material'

const BlocoPraticas = React.forwardRef(({ 
                        temasestudo, 
                        descobertaguiada, 
                        temaadicionado, 
                        selectedBloco,
                        textareavalue,
                        onChangecallback,
                        onKeyDowncallback,
                        onClickcallback,
                        onClickRemovecallback}, ref) => {

  const [isCollapse, setisCollapse] = useState(false);                 
  const [disableSalvar, setDisableSalvar] = useState(true);     
  const [temaSelecionadoAnterior, setTemaSelecionadoAnterior] = useState();     
  
  useEffect(() => {
    if (temaSelecionadoAnterior && temaSelecionadoAnterior.nome !== temasestudo[selectedBloco].nome) {
      setDisableSalvar(true);
    }
  }, [selectedBloco]);

  function handleonChangecallback(event) {
    onChangecallback(event);
    setDisableSalvar(false);
    setTemaSelecionadoAnterior(temasestudo[selectedBloco]);
  };

  function handleonClickcallback() {
    onClickcallback();
    setDisableSalvar(true);
  };

  return (
    <div className='bloco_praticas'>
      {/* Testa se temas de estudo foram carregados, e se existem slides de hands on */}
      {temasestudo.length ? 
      <div style={{width:"100%", height:"20%", display: "flex", flexDirection: 'column'}}>
       
        <div style={{width:"80%", height:"100%"}}>
        <textarea
          title='EdicaoPratica' 
          // ref={textareaRef}
          value={textareavalue == null ? temasestudo[selectedBloco][descobertaguiada ? 'descobertaguiada' : 'handson'] : textareavalue}
          onChange={handleonChangecallback}
          style={{ 
          width: '80%',
          height: '140px', 
          // overflowWrap: 'break-word',
          overflowY: textareavalue.length >= 100 ? 'scroll' : 'auto',
          // resize: 'none' // Impede que o usuário redimensione o textarea manualmente
         }}
          placeholder="Edite aqui..."
          onKeyDown={onKeyDowncallback}
          
        />
       <Divider sx={{ my: 1.0 }} />
        <div style={{display: 'flex', flexDirection: 'row-reverse', justifyContent: 'space-between', width: '80%', }}>
        <button style={{ width: '130px', 
                         borderRadius: '10px', 
                         fontSize: 'small', 
                         height: '28px', 
                         display: 'flex', 
                         justifyContent: 'center', 
                         alignItems: 'center', 
                         backgroundColor: disableSalvar ? 'gray' : 'black', 
                         color: 'white', 
                         border: 'none',
                         cursor: disableSalvar ? 'not-allowed' : 'pointer'  }}  
                onClick={handleonClickcallback}
                disabled={disableSalvar}>
                  <strong>Salvar</strong>
        </button>
        <button style={{ width: '130px', 
                         borderRadius: '10px', 
                         fontSize: 'small', 
                         height: '28px', 
                         display: 'flex', 
                         justifyContent: 'center', 
                         alignItems: 'center', 
                         border: '1px solid grey'}} 
                onClick={onClickRemovecallback}>
                  <strong>Excluir</strong>
                  </button>
        </div>
        <Divider sx={{ my: 0.2 }} />
        <div style={{ width:'83%', 
                      display: 'flex', 
                      justifyContent:'flex-end'}}> 
          <Button style={{ backgroundColor:'transparent', 
                           color:'grey', 
                           textDecoration: 'underline', 
                           fontSize: 'small', 
                           height: '28px', 
                           display: 'flex', 
                           justifyContent: 'center', 
                           alignItems: 'center'}}  
                  onClick={ () => setisCollapse(!isCollapse) }>
                    ver todos
          </Button>
        </div>
       
        </div>
       
        <Collapse in={isCollapse}>
        <BlocoSequenciaPraticas
          ref={ref} 
          temasestudo={temasestudo} 
          descobertaguiada={descobertaguiada} 
          temaadicionado={temaadicionado} 
          // onModificarLista={modificarLista} 
          selectedBloco={selectedBloco}/>
        </Collapse>
        
      </div>
      : 
      <div style={{width:"40%", height:"400px", display: "flex", justifyContent: "center"}} >
        {/* <div style={{width:"600px", height:"400px", display: "flex", justifyContent: "center", alignItems: "center", border:"1px solid black"}} >Por favor, selecione um módulo</div> */}
        <div style={{width:"100%", height:"100%", display: "flex", justifyContent: "center", alignItems: "center"}} >Por favor, selecione um módulo</div>
      </div>}
    </div>
  );
});

export default BlocoPraticas;