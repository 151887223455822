import React, { useState, useEffect } from "react"; // Importa o módulo React e os hooks useState e useEffect
import "./MentorModulo.css"; // Importa o arquivo CSS associado ao componente
import Topo from "../AreaFacilitador/Topo";
import { Row, Col } from "react-bootstrap";
import DropdownList from '../DropdownList/DropdownList';
import ListGroup from 'react-bootstrap/ListGroup';
import ArrowLeftIcon from '@mui/icons-material/ArrowLeft';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import Button from 'react-bootstrap/Button'; // Importação do componente de botão do React Bootstrap
import DialogText from '../DialogText';
import ModalAviso from '../Modal/ModalAviso';

const apiUrl = process.env.REACT_APP_API_HOST;
// const apiUrl = "http://127.0.0.1:5000";

function MentorModulo() {
  // Declaração do componente funcional MentorModulo
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  // Variaveis relacionadas ao itens dos dropdownList
  const [optionsFacilitadores, setOptionsFacilitadores] = useState([]);
  const [optionsModulos, setOptionsModulos] = useState([]);
  const [optionsFacId, setOptionsFacId] = useState(0);
  const [optionsModId, setOptionsModId] = useState(0);
  // Variaveis relacionadas ao itens das listas
  const [nonRelatedId, setNonRelatedId] = useState(null);
  const [relatedId, setRelatedId] = useState(null);
  const [facilitadores, setFacilitadores] = useState([]);
  const [modulos, setModulos] = useState([]);
  const [modificacoes, setModificacoes] = useState([]);
  const [mostrarModalSalvar, setMostrarModalSalvar] = useState(false);
  const [textoModalSalvar, setTextoModalSalvar] = useState('');

  useEffect(() => {
    document.body.style.overflow = 'hidden';
    setLoading(true);
    setOpen(true);
    const fetches = [];

    fetches.push(fetchFacilitadorAtivo());
    fetches.push(fetchModuloAtivo());
    if (fetches.length > 0) {
      Promise.all(fetches)
        .then((results) => {
          let resultIndex = 0;

          setOptionsFacilitadores(results[resultIndex]);
          resultIndex++;
          setOptionsModulos(results[resultIndex]);
        })
        .catch((error) => {
          console.error("Erro ao buscar dados:", error);
        })
        .finally(()=>{
          setLoading(false);
          setOpen(false);
        });
    }
  }, []);

  const fetchFacilitadorAtivo = async (idhabilidade, idlogin) => {
    try {
      const response = await fetch(
        `${apiUrl}/api/getfacilitador?ativo`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      if (!response.ok) {
        throw new Error(
          `Erro na busca dos facilitadores! Status: ${response.status}`
        );
      }
      const data = await response.json();
      return data;
    } catch (error) {
      console.error("Erro na requisição:", error);
      throw error;
    }
  };

  const fetchModuloAtivo = async (idhabilidade, idlogin) => {
    try {
      const response = await fetch(
        `${apiUrl}/api/getmodulo?ativo`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      if (!response.ok) {
        throw new Error(
          `Erro na busca da habilidade! Status: ${response.status}`
        );
      }
      const data = await response.json();
      return data;
    } catch (error) {
      console.error("Erro na requisição:", error);
      throw error;
    }
  };

  const handleSelectFacilitador = async (idfacilitador) => {
    setLoading(true);
    setOpen(true);
    setOptionsFacId(idfacilitador); 
    setOptionsModId(null);
    const response = await fetch(`${apiUrl}/api/getmodulo?ativo&idfacilitador=${idfacilitador}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });
    const data = await response.json();
    if (!response.ok) {
      throw new Error(data.message || "Erro na solicitação");
    }
    console.log(data);
    console.log(data.filter((item) => {if (item.ativo == 1) return item.nome}))
    setModulos(data);
    setFacilitadores([]);
    setLoading(false);
    setOpen(false);
  };

  const handleSelectModulo = async (idmodulo) => {
    setLoading(true);
    setOpen(true);
    setOptionsModId(idmodulo); 
    setOptionsFacId(null);
    const response = await fetch(`${apiUrl}/api/getfacilitador?ativo&idmodulo=${idmodulo}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });
    const data = await response.json();
    if (!response.ok) {
      throw new Error(data.message || "Erro na solicitação");
    }
    console.log(data);
    console.log(data.filter((item) => {if (item.ativo == 1) return item.nome}))
    setFacilitadores(data);
    setModulos([]);
    setLoading(false);
    setOpen(false);
  };

  const handleAtivar = async (status) => {
    setLoading(true);
    setOpen(true);
    if (nonRelatedId) {
      console.log(nonRelatedId);
      if (nonRelatedId.tipo === 'facilitador') {
        setFacilitadores(facilitadores.map((facilitador) => {
            if(facilitador.id === nonRelatedId.id) {
              return {
                ...facilitador,
                ativo: 1,
              };
            }
            return facilitador;
          })
        );
        handleModificacoes(nonRelatedId.id, optionsModId, 1);
      }
      else if (nonRelatedId.tipo === 'modulo') {
        setModulos(modulos.map((modulo) => {
            if(modulo.id === nonRelatedId.id) {
              return {
                ...modulo,
                ativo: 1,
              };
            }
            return modulo;
          })
        );
        handleModificacoes(optionsFacId, nonRelatedId.id, 1);
      }
      // Reseta a seleção
      setNonRelatedId(null);
    }
    setLoading(false);
    setOpen(false);
    // Segundo atualiza as variáveis do back-end
  }; 
  
  const handleDesativar = async (status) => {
    setLoading(true);
    setOpen(true);
    if (relatedId) {
      console.log(relatedId);
      if (relatedId.tipo === 'facilitador') {
        setFacilitadores(facilitadores.map((facilitador) => {
            if(facilitador.id === relatedId.id) {
              return {
                ...facilitador,
                ativo: 0,
              };
            }
            return facilitador;
          })
        );
        handleModificacoes(relatedId.id, optionsModId, 0);
      }
      else if (relatedId.tipo === 'modulo') {
        setModulos(modulos.map((modulo) => {
            if(modulo.id === relatedId.id) {
              return {
                ...modulo,
                ativo: 0,
              };
            }
            return modulo;
          })
        );
        handleModificacoes(optionsFacId, relatedId.id, 0);
      }
      // Reseta a seleção
      setRelatedId(null);
    }
    // Segundo atualiza as variáveis do back-end
    setLoading(false);
    setOpen(false);
  };  

  const handleModificacoes = (idfacilitador, idmodulo, ativo) => {
    setLoading(true);
    setOpen(true);
    // Filtra as modificações diferentes
    let filteredModificacoes = modificacoes.filter(
      (mod) => !(mod.idfacilitador === idfacilitador && mod.idmodulo === idmodulo && mod.ativo == ativo)
    );
    // Entre as modificações diferentes, verifica se encontra uma de mesmo facilitador e módulo, mas com valor de ativo contrário
    const existsAtivoContrario = filteredModificacoes.some(
      (mod) => mod.idfacilitador === idfacilitador && mod.idmodulo === idmodulo && mod.ativo == !ativo
    );
    // Se existe modificacao com ativo igual ao contrário, remove essa modificação do idfacilitador e idmodulo
    if (existsAtivoContrario) {
      setModificacoes(modificacoes.filter(
        (mod) => !(mod.idfacilitador === idfacilitador && mod.idmodulo === idmodulo && mod.ativo == !ativo)
      ));
    }
    // Se não existe a modificação com ativo contrária, verifica se a modificação já existe
    // Se não existir, adiciona como uma nova modificação
    else if (filteredModificacoes.filter(
          (mod) => (mod.idfacilitador === idfacilitador && mod.idmodulo === idmodulo && mod.ativo == ativo)
        ).length<= 0) {
        setModificacoes([
          ...filteredModificacoes,
          { idfacilitador: idfacilitador, idmodulo: idmodulo, ativo: ativo }
        ]);
    }
    setLoading(false);
    setOpen(false);
  };

  const handleRelatedClick = (event, id, tipo) => {
    event.preventDefault(); // Previne a adição do link à URL
    console.log(id);
    setRelatedId({'tipo':tipo, 'id':id});
  };

  const handleNonRelatedClick = (event, id, tipo) => {
    event.preventDefault(); // Previne a adição do link à URL
    setNonRelatedId({'tipo':tipo, 'id':id});
  };

  const onclickcallbackSaving = () =>{
    setLoading(true);
    setOpen(true);
    console.log(optionsFacId);
    console.log(optionsModId);
    fetch(`${apiUrl}/api/updatefacilitadoresmodulo`, {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
      },
      // body: JSON.stringify({ "idmodulo":optionsModId, "idfacilitador":optionsFacId, "modulos": modulos, "facilitadores": facilitadores }),
      body: JSON.stringify({ "modificacoes":modificacoes }),
    })
    .then((response) => response.json())
    .then((data) => {
      // esvazia array de modificações
      setModificacoes([]);
      setTextoModalSalvar("Operação de Mentor-Módulo concluída");
      setMostrarModalSalvar(true);
      // setLinkcomecepeloporque(data.linkcomecepeloporque);
      // // Redireciona o usuário para uma nova página da web
      // window.open(data.linkcomecepeloporque, '_blank');
    })
    .catch((error) => {console.error('Erro na solicitação:', error);
      alert('Erro na solicitação:', error);
      // setTextoModalSalvar("Houve erro no inesperado salvamento. Por favor, tente novamente mais tarde ou contate o atendimento.");
      // setMostrarModalSalvar(true);
    })
    .finally(() => {
      setLoading(false);
      setOpen(false);
    });
  };

  return (
    <div id="container">
      <div id="section-login">
        <Topo flagadmin={true} />
      </div>
      <Row>
        <Col>
          {optionsFacilitadores.length>0 && (
            <DropdownList
              placeholder='Selecione um facilitador '
              options={optionsFacilitadores.map((item) => {return item.nome})}
              selectedOption={optionsFacilitadores.find((fac) => fac.id === optionsFacId)?.nome}
              onSelect={(facilitador)=>{handleSelectFacilitador(optionsFacilitadores.find((fac) => fac.nome === facilitador).id);}}
            />
          )}
        </Col>
        <Col>
          {optionsModulos.length>0 && (
            <DropdownList
              placeholder='Selecione um modulo'
              options={optionsModulos.map((item) => {return item.nome})}
              selectedOption={optionsModulos.find((mod) => mod.id === optionsModId)?.nome}
              onSelect={(modulo)=>{handleSelectModulo(optionsModulos.find((mod) => mod.nome === modulo).id);}}
            />
          )}
        </Col>
      </Row>
      <Row style={{width:"100%"}}>
        <Col md={4} sm={4}>
          {modulos.length>0 && 'Módulos disponíveis para o facilitador'}
          {facilitadores.length>0 && 'Facilitadores disponíveis para o módulo'}
          <ListGroup className="ListaItens" style={{border: '1px black solid'}}>
            {modulos.length>0 &&
              (modulos.filter((item) => {if (item.ativo == 0) return item.nome}).map((modulo, index) => {
                return(
                <ListGroup.Item action
                  href={`#link${modulo.id}`} 
                  onClick={(e) => handleNonRelatedClick(e, modulo.id, 'modulo')}>
                  {modulo.nome}
                </ListGroup.Item>)})
              )}
            {facilitadores.length>0 &&
              (facilitadores.filter((item) => {if (item.ativo == 0) return item.nome}).map((facilitador, index) => {
                return(
                <ListGroup.Item action href={`#link${facilitador.id}`} onClick={(e) => handleNonRelatedClick(e, facilitador.id, 'facilitador')}>
                  {facilitador.nome}
                </ListGroup.Item>)})
              )}
          </ListGroup>
        </Col>
        <Col md={4} sm={4} className="centercolumn">
          {/* <div style={{ display: 'flex', justifyContent: 'center', marginTop: '1%'}}> */}
              <Button 
                className="sidebutton"
                onClick={handleDesativar}
                disabled={!relatedId || relatedId.length<=0}>
                <ArrowLeftIcon
                  style={{ color: 'black', border: 'none', width: '70px', height: '40px' }}
                />
              </Button>
              <Button 
                className="sidebutton"
                style={{marginTop: '1vh'}}
                onClick={handleAtivar}
                disabled={!nonRelatedId || nonRelatedId.length<=0}>
                <ArrowRightIcon
                  style={{ color: 'black', border: 'none', width: '70px', height: '40px' }}
                />
              </Button>
          {/* </div> */}
        </Col>
        <Col md={4} sm={4}>
          <Row>
            {modulos.length>0 && 'Módulos registrados para o facilitador'}
            {facilitadores.length>0 && 'Facilitadores registrados para o módulo'}
            <ListGroup className="ListaItens" style={{border: '1px black solid'}}>
              {modulos.length>0 &&
                (modulos.filter((item) => {if (item.ativo == 1) return item.nome}).map((modulo, index) => {
                  return(
                  <ListGroup.Item action href={`#link${modulo.id}`} onClick={(e) => handleRelatedClick(e, modulo.id, 'modulo')}>
                    {modulo.nome}
                  </ListGroup.Item>)})
                )}
              {facilitadores.length>0 &&  
                (facilitadores.filter((item) => {if (item.ativo == 1) return item.nome}).map((facilitador, index) => {
                  return(
                  <ListGroup.Item action href={`#link${facilitador.id}`} onClick={(e) => handleRelatedClick(e, facilitador.id, 'facilitador')}>
                    {facilitador.nome}
                  </ListGroup.Item>)})
                )}
            </ListGroup>
          </Row>
          <Row id='SaveButtonRow'>
            <Button style={{width: '20vw', 
                            borderRadius: '10px', 
                            fontSize: 'medium', 
                            height: '5vh', 
                            display: 'flex', 
                            justifyContent: 'center', 
                            alignItems: 'center'}}  
                    variant="dark" 
                    onClick={onclickcallbackSaving} 
                    // disabled={!selectedModulo}
                    >
              <strong>Salvar</strong>
            </Button>
          </Row>
        </Col>
      </Row>
      <DialogText openflag={open} dialogtitle="Por favor, aguarde" dialogcontexttextflag={loading} />
      <ModalAviso texto={textoModalSalvar} show={mostrarModalSalvar} setTextoModalSalvar = {setTextoModalSalvar} setMostrarModalSalvar = {setMostrarModalSalvar}/>
    </div>
  );
}

export default MentorModulo;
