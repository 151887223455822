//auth.js
// export const isAuthenticated = () => {
//   // Implemente a lógica real de autenticação aqui
//   // Por exemplo, você pode verificar se há um token de autenticação válido
//   return true; // Retorne true se o usuário estiver autenticado, caso contrário, retorne false
// };

export function setAuthData(authData) {
  localStorage.setItem('authData', JSON.stringify(authData));
}

export function getAuthData() {
  const authData = localStorage.getItem('authData');
  return authData ? JSON.parse(authData) : null;
}

export function clearAuthData() {
  localStorage.removeItem('authData');
}

export function isAuthenticated() {
  const authData = getAuthData();
  return authData !== null && authData.token !== null; // Verifique se o token de autenticação está presente e válido
}
