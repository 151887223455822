import React, { useState, useRef, useEffect } from 'react';
import './DropdownList.css';

import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

function DropdownList({ placeholder, options, selectedOption, onSelect }) {
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef();

  // UseEffect para adicionar um ouvinte de eventos de clique ao documento
  useEffect(() => {
    function handleOutsideClick(event) {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        // Clique fora da lista suspensa, então feche-a
        setIsOpen(false);
      }
    }

    // Adicione o ouvinte de eventos quando a lista suspensa estiver aberta
    if (isOpen) {
      document.addEventListener('click', handleOutsideClick);
    } else {
      document.removeEventListener('click', handleOutsideClick);
    }

    // Limpe o ouvinte de eventos quando o componente é desmontado
    return () => {
      document.removeEventListener('click', handleOutsideClick);
    };
  }, [isOpen]);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleOptionClick = (option) => {
    onSelect(option);
    setIsOpen(false);
  };

  return (
    <div ref={dropdownRef} className="dropdown-list">
      <div className="selected-option" onClick={toggleDropdown}>
        {selectedOption || placeholder}{<ArrowDropDownIcon/>}
      </div>
      {isOpen && (
        <ul className="options">
          {options.map((option, index) => (
            <li className="item" key={index} onClick={() => handleOptionClick(option)}>
              {option}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
}

export default DropdownList;
