// BlocoPratica.js
import React, { useState, useRef, useEffect } from 'react';

const BlocoPratica = ({ id, texto='', descobertaguiada, index, isSelected }) => {
  const style = {
    border: '1px solid #ccc',
    margin: '4px',
    padding: '8px',
    backgroundColor: 'white',
    opacity: 1,
    border: isSelected ? '1px solid yellow' : '1px solid black',
    fontWeight: isSelected ? 'bold' : 'normal',
    width: '90%',
  };

  return (
    <div id={`bloco_p-${index}`} style={style}>
      {texto.replace(/[\n\t]/g, '') === '' ? (descobertaguiada ? 'Sem descoberta guiada': 'Sem hands on') : texto}
      {/* {texto ? (texto.replace(/[\n\t]/g, '') === '' ? (descobertaguiada ? 'Sem descoberta guiada': 'Sem hands on') : texto) : ''} */}
    </div>    
  );
};

export default BlocoPratica;
