// BlocoTema.js
import React, { useState, useEffect } from 'react';

const BlocoTema = ({ id, 
                     texto, 
                     aula, 
                     index,
                     onSelect, 
                     isSelected, 
                     qtdetemas }) => {
  const [posicaoMouse, setPosicaoMouse] = useState({ x: 0, y: 0 });
  const [mouseSolto, setMouseSolto] = useState(false);
  const [isClicked, setIsClicked] = useState(false);
  const [selectedBloco, setSelectedBloco] = useState();
  const [larguraDaDiv, setLarguraDaDiv] = useState(0);

  // Função para calcular a largura da div
  const calcularLarguraDaDiv = () => {
    const larguraDaTela = window.innerWidth;
    const larguraDesejada = larguraDaTela / 7;
    setLarguraDaDiv(larguraDesejada);
  };

  // Calcula a largura da div quando o componente é montado e quando a janela é redimensionada
  useEffect(() => {
    calcularLarguraDaDiv();
    window.addEventListener('resize', calcularLarguraDaDiv);
    return () => {
      window.removeEventListener('resize', calcularLarguraDaDiv);
    };
  }, []);

  const handleMouseMove = (e) => {
    setPosicaoMouse({ x: e.clientX, y: e.clientY });
  };

  const handleMouseDown = () => {
    setMouseSolto(false);
    setIsClicked(true);
    setSelectedBloco(index);
  };

  const handleBlocoClick = () => {
    onSelect(id);
  };    

  const style = {
    border: '1px solid #ccc',
    paddingRight: '3px',
    paddingLeft: '3px',
    backgroundColor: 'white',
    opacity: 1,
    backgroundColor: isSelected ? '#87ef96' : 'white',
    fontWeight: isSelected ? 'bold' : 'normal',
    width: `${larguraDaDiv}px`,
    height: '100%',
    cursor: 'pointer',
    fontSize: '1em' 
  };
  return (
    <div id={`bloco-${index}`} style={style} onMouseMove={handleMouseMove} onMouseDown = {handleMouseDown} onClick={handleBlocoClick}>
      <div style={{ left: 'auto', position: 'relative', transition: 'left 0.2s', padding: '1em' }}>
        {texto}
      </div>
    </div>
  );
};

export default BlocoTema;
