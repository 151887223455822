// BlocoSequenciaPraticas.js
import React, { useEffect, useState } from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import BlocoPratica from './BlocoPratica';
import './BlocoSequenciaPraticas.css'

const BlocoSequenciaPraticas = React.forwardRef(({temasestudo, descobertaguiada, temaadicionado=false, selectedBloco}, ref) => {
  const [blocoPratica, setBlocoPratica] = useState(
    // temasestudo
    [
      { id: 1, nome: 'Tema 1', codigo: 0, isSelected: true },
      { id: 2, nome: 'Tema 2', codigo: 1, isSelected: false },
      // Adicione mais sub-blocos conforme necessário
    ]
  );

  // Hook que processa sempre que a props temasestudo for modificada
  useEffect(() => {
    // Tranformando o array de strings em um array de objetos com chaves id e texto
    const arrayDeObjetos = temasestudo.map((item, index) => ({
      id: index + 1, // Adicionando 1 para garantir que o ID seja único
      nome: item.nome,
      codigo: item.codigo,
      descobertaguiada: item.descobertaguiada,
      handson: item.handson,
      isSelected: !temaadicionado ? (index === 0 ? true : false) : (index === (temasestudo.length - 1) ? true : false), // O primeiro tema é selecionado por padrão
      link_apresentacao: item.link_apresentacao,
    }));
    setBlocoPratica(arrayDeObjetos);
  }, [temasestudo, temaadicionado]);

  return (
    <DndProvider backend={HTML5Backend}>
      <div className="BlocoSequenciaPraticas" ref={ref}>
        <div className="blocoseqpratica">
          {blocoPratica.map((tema, index) => {
            return (
              <BlocoPratica
                id={tema.id}
                texto={descobertaguiada ? tema.descobertaguiada : tema.handson}
                descobertaguiada = {descobertaguiada}
                index={index}
                isSelected={index === selectedBloco}
              />  
            )        
          })}
          {/* {mostrarInput && (
            <div className="janela-input">
              <label htmlFor="campo-input">Novo:</label>
              <input type="text" id="campo-input" />
              <button onClick={fecharJanelaInput}>Fechar</button>
            </div>
          )} */}
        </div>
      </div>
    </DndProvider>
  );
});

export default BlocoSequenciaPraticas;
