import React, { useState, useEffect } from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';

function ModalGeracaoLadoALadoB({ show, setMostrarModalladoAladoB, generateLadoALadoB }) {
  // const [tempoRestante, setTempoRestante] = useState(5); // 5 segundos para desfazer a ação
  // const [timer, setTimer] = useState(null); // Declaração da variável timer
  // const [isVisible, setIsvisible] = useState(show); // Declaração da variável timer

  const handleOK = () => {
    // setTextoModalCompilacao('');
    generateLadoALadoB();
    setMostrarModalladoAladoB(false);
    // onCloseModalCallback();
    // setTempoRestante(5);
  };

  return (
    // <div className={`modal ${show ? 'fade-slide-enter' : 'fade-slide-exit'}`}>
    <div>
    <Modal show={show}
          //  backdrop="true"
           size="md"
           centered={false}
          //  dialogClassName="bottom-right-modal"
          //  contentClassName="modal3"
          >
      <Modal.Dialog 
          //  contentClassName="modal3"
           style={{margin:0}}>
        {/* <Modal.Header closeButton>
          <Modal.Title>Modal title</Modal.Title>
        </Modal.Header> */}

        <Modal.Body>
          {/* {texto} */}
          <br/>
          <p>Você não possui apresentação de lado A lado B registrada. Gostaria de gerá-la?.</p>
          {/* <ProgressBar variant="info" animated now={Math.ceil(tempoRestante/5)} /> */}
        </Modal.Body>

        <Modal.Footer>
          <Button variant="dark" onClick={handleOK}>Gerar</Button>
          <Button variant="primary" onClick={() => setMostrarModalladoAladoB(false)}>Cancelar</Button>
        </Modal.Footer>
      </Modal.Dialog>
      {/* <div className="modal-content">
        <p>Desfazer em {tempoRestante} segundos</p>
        <button onClick={desfazer}>Desfazer</button>
      </div> */}
      </Modal>
    </div>
  );
}

export default ModalGeracaoLadoALadoB;