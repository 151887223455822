import './AreaFacilitador.css';
import { useState } from 'react';
import BlocoPraticas from './BlocoPraticas';
import Collapse from 'react-bootstrap/Collapse';
import SettingsIcon from '@mui/icons-material/Settings';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import Button from 'react-bootstrap/Button';
import TemporaryToast from './TemporaryToast';
import { Row, Col } from 'react-bootstrap';

function BlocoExercicios({temasestudo,
                          temaadicionado,
                          selectedBloco,
                          descobertaguiada_ref,
                          descobertaguiada_text,
                          descobertaguiada_onChangecallback,
                          descobertaguiada_onKeyDowncallback,
                          descobertaguiada_onClickcallback,
                          descobertaguiada_onClickRemovecallback,
                          handson_ref,
                          handson_text,
                          handson_onChangecallback,
                          handson_onKeyDowncallback,
                          handson_onClickcallback,
                          disabledcondition,
                          handson_onClickRemovecallback}) {
  const [opendescobertaguiada, setOpendescobertaguiada] = useState(true);
  const [openhandson, setOpenhandson] = useState(true);
  const [ativaToastDescobertaguiada, setAtivaToastDescobertaguiada] = useState(false);
  const [ativaToastHandson, setAtivaToastHandson] = useState(false);

  function handleDescobertaguiada_onClickcallback() {
    setAtivaToastDescobertaguiada(true);
    descobertaguiada_onClickcallback();
  };

  function handleHandson_onClickcallback() {
    setAtivaToastHandson(true);
    handson_onClickcallback();
  };

  return (
    <Row id="slides_exercicios" style={{ marginTop: "5vh" }}>
      {  !disabledcondition && (
      <Col id='colDescobertaguiada' style={{display:'flex', flexDirection: 'column', width: '100%', alignItems: 'flex-start', padding: '0vw'}}>
        <div className='arrowCollapse' onClick={() => { setOpendescobertaguiada(!opendescobertaguiada); }} style={{display: 'flex', flexDirection: 'row', cursor: 'pointer', width: "50%", justifyContent: 'flex-start', fontSize: '1.5em', marginLeft: '15%'}} >
         <strong> Descoberta Guiada: </strong>
        </div >
        {/* <Collapse in={!opendescobertaguiada}> */}
          <div style={{paddingLeft: '15%', width: '100%'}}>
            <BlocoPraticas ref={descobertaguiada_ref} temasestudo= {temasestudo} 
                            descobertaguiada= {true} temaadicionado= {temaadicionado} 
                            selectedBloco={selectedBloco} textareavalue={descobertaguiada_text}
                            onChangecallback={descobertaguiada_onChangecallback} onKeyDowncallback={descobertaguiada_onKeyDowncallback}
                            onClickcallback={handleDescobertaguiada_onClickcallback} onClickRemovecallback={descobertaguiada_onClickRemovecallback}/>
          </div>
        {/* </Collapse> */}
      </Col>)}
      { !disabledcondition && (<Col id='colHandson' style={{display:'flex', flexDirection: 'column', width: '100%', alignItems: 'flex-start', padding: '0vw'}}>
        <div className='arrowCollapse' onClick={() => { setOpenhandson(!openhandson); }} style={{display: 'flex', flexDirection: 'row', cursor: 'pointer', width: "50%", marginLeft: '15%', fontSize: '1.5em' }} >
         <strong> Hands on:</strong>
        </div>
      
          <div style={{paddingLeft: '15%', width: '100%'}}>
            <BlocoPraticas ref={handson_ref} temasestudo= {temasestudo} 
                            descobertaguiada= {false} temaadicionado= {temaadicionado} 
                            selectedBloco={selectedBloco} textareavalue={handson_text}
                            onChangecallback={handson_onChangecallback} onKeyDowncallback={handson_onKeyDowncallback}
                            onClickcallback={handleHandson_onClickcallback} onClickRemovecallback={handson_onClickRemovecallback}/>
          </div>
       
      </Col>)}
      {ativaToastDescobertaguiada && <TemporaryToast message="Descoberta Guiada salva com sucesso!" duration={1750} callback={setAtivaToastDescobertaguiada} />}
      {ativaToastHandson && <TemporaryToast message="Hands On salvo com sucesso!" duration={1750} callback={setAtivaToastHandson} />}
    </Row>
  );
}

export default BlocoExercicios;